<template>
  <b-row class="d-flex flex-row justify-content-center mt-2 rounded-lg">
    <b-col v-if="$route.params.id">
      <b-row class="rounded-lg p-2 shadow-lg d-flex justify-content-end">
        <b-col cols="12" md="2" class="text-center py-4">
          <img src="@/assets/images/icons/profile-change-password.png" alt="" />
          <p class="bank-text text-center py-1">Change Password</p>
        </b-col>
        <b-col cols="12" md="10">
          <validation-observer ref="passwordForm">
            <form>
              <b-row>
                <b-col>
                  <b-form-group>
                    <p class="title-text">Change Password</p>
                    <p class="text-danger">
                      Rules of Strong Password (A - Z | a - z | 0 - 9 | Special
                      Character | Minimum 8 Characters)
                    </p>
                    <div v-if="userData.roles[0].name === 'employee'">
                      <b-form-group
                        invalid-feedback="Old Password Is required"
                        :state="oldPassword"
                      >
                        <label for="text-password" class="label-text"
                          >Existing Password</label
                        >
                        <validation-provider
                          #default="{ errors }"
                          name="Old Password"
                          vid="OldPassword"
                          rules="required|min:8"
                        >
                          <b-form-input
                            id="a-password"
                            v-model="passwordForm.old_password"
                            :state="errors.length > 0 ? false : null"
                            type="password"
                            placeholder="Type your old password as condition"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <b-form-group
                      invalid-feedback="New Password Is required"
                      :state="newPassword"
                    >
                      <label for="text-password" class="label-text"
                        >New Password</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="New Password"
                        vid="Password"
                        rules="required|password|min:8"
                      >
                        <b-form-input
                          id="ac-password"
                          v-model="passwordForm.password"
                          :state="errors.length > 0 ? false : null"
                          type="password"
                          placeholder="Type your new password as condition"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <span class="text-danger" v-if="showValidationMessage">{{
                      validationMessage
                    }}</span>
                    <b-form-group
                      invalid-feedback="Password Confirmation Is required"
                      :state="againNewPassword"
                    >
                      <label for="text-password" class="label-text"
                        >Re-Type New Password</label
                      >
                      <validation-provider
                        #default="{ errors }"
                        name="Re-Type New Password"
                        rules="required|confirmed:Password"
                      >
                        <b-form-input
                          id="ar-password"
                          v-model="passwordForm.password_confirmation"
                          :state="errors.length > 0 ? false : null"
                          type="password"
                          placeholder="Re-Type your new password as condition"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <span class="text-danger" v-if="showValidationMessage">{{
                      validationMessage
                    }}</span>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="id > 0 && $can('update password', 'Employee')">
                <b-col class="text-right">
                  <button
                    class="btn update-btn"
                    @click.prevent="updatePassword"
                  >
                    Confirm
                  </button>
                </b-col>
              </b-row>
            </form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else class="card text-center py-4">
      <p class="text-warning">please insert previous data</p>
    </b-col>
  </b-row>
</template>

<script>
import { toast } from "@/utils/toast";
import { email, required, password } from "@validations";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    basic_info: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      passwordForm: {
        id: this.$route.params.id,
        old_password: "",
        password: "",
        password_confirmation: "",
      },
      oldPassword: null,
      newPassword: null,
      againNewPassword: null,
      showValidationMessage: false,
      email,
      required,
      password,
    };
  },
  methods: {
    updatePassword() {
      this.$refs.passwordForm.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch("employee/updatePassword", this.passwordForm)
            .then((res) => {
              if (res.status === 200) {
                toast(
                  "Successfully Updated",
                  "CheckCircleIcon",
                  "success",
                  "Password Updated"
                );
              } else {
                toast(
                  "Error",
                  "AlertTriangleIcon",
                  "error",
                  "Password Not Updated"
                );
              }
            })
            .catch((error) => {
              toast(
                error.response?.data?.error || "Error",
                "AlertTriangleIcon",
                "danger",
                error.response?.data?.message || "Password Not Updated"
              );
            });
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.label-text {
  /* 20 regular */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.title-text {
  /* 32 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  /* Text main */

  color: #515b60;
}

.bank-text {
  color: #7190ef;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
}
</style>
