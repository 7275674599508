<template>
  <b-row
    class="d-flex flex-row justify-content-center mt-2 rounded-lg"
  >
    <b-col v-if="$route.params.id">
      <b-form ref="form">
        <b-row class="rounded-lg p-2 shadow-lg d-flex justify-content-end">
          <b-col
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <img src="@/assets/images/icons/profile-next-to-keen.png" alt="" />
            <p class="keen-text text-center py-1">Next To Kin</p>
          </b-col>
          <b-col cols="12" md="10">
            <b-row class="rounded-lg">
              <b-col class="text-left py-1">
                <h3>Next To Kin</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  invalid-feedback="Kin Name Is Required"
                  :state="nameState"
                >
                  <label for="">Name <span class="text-danger">*</span></label>
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="keen.name"
                    placeholder="Enter Your Close Relative’s  Name"
                    required
                    :state="nameState"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Relation With Employee"
                  label-for="Relation With Employee"
                >
                  <b-form-input
                    v-model="keen.relation"
                    placeholder="Enter The Relation With Employee"
                    required
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Address" label-for="Living Address">
                  <b-form-input
                    v-model="keen.address"
                    placeholder="ig. H#00, St.#00, Suburb, City, Zip Code"
                    required
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  invalid-feedback="Kin Phone Number Is Required"
                  :state="phone"
                >
                  <label for="">Phone <span class="text-danger">*</span></label>
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="keen.phone"
                    placeholder="Enter Phone Number"
                    required
                    :state="phone"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  invalid-feedback="Kin Email Address Is Required"
                  :state="email"
                >
                  <label for="">Email</label>
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="keen.email"
                    :state="email"
                    type="email"
                    placeholder="Enter Email"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="id > 0 && $can('update', 'Employee')">
              <b-col class="text-right">
                <button class="btn update-btn" @click.prevent="handleSubmit">
                  Update Kin
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col v-else class="card text-center py-4">
      <p class="text-warning">please insert previous data</p>
    </b-col>
  </b-row>
</template>

<script>
import { toast } from "@/utils/toast";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BRow,
} from "bootstrap-vue";

export default {
  props: {
    keen: {
      type: Object,
      default: {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
  },
  data() {
    return {
      nameState: null,
      phone: null,
      email: null,
    };
  },
  methods: {
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      if (this.keen.name !== "") {
        valid = true;
        this.nameState = valid;
      } else {
        valid = false;
        this.nameState = valid;
      }
      if (this.keen.phone !== "") {
        valid = true;
        this.phone = valid;
      } else {
        valid = false;
        this.phone = valid;
      }
      return valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      if (this.keen.id) {
        this.updateKin();
      }
    },
    updateKin() {
      this.$store
        .dispatch("employee/updateKeenInfo", this.keen)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Kin Update Successfully"
            );
          } else {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Error Updating Employee"
            );
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.error;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.keen-text {
  color: #7190ef;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
}
</style>
