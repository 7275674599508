<template>
  <b-row
    v-if="$route.params.id"
    class="d-flex flex-row justify-content-center mt-2 rounded-lg"
    id="all-time-dropdown"
  >
    <b-col v-show="skillStep === 1">
      <b-form>
        <b-row
          class="rounded-lg p-2 shadow-lg d-flex justify-content-end"
        >
          <b-col cols="12" md="2" class="text-center py-4">
            <img src="@/assets/images/icons/profile-skill-test.png" alt="" />
            <p class="bank-text text-center py-1">Skill Test</p>
          </b-col>
          <b-col cols="12" md="10">
            <b-row class="rounded-lg">
              <b-col class="text-left py-1">
                <div class="d-flex justify-content-between py-1">
                  <div class="c-width pr-2">
                    <h2 v-if="items.length > 0">Previous Test Summary</h2>
                    <b-table
                      v-if="items.length > 0"
                      :fields="parentFields"
                      show-empty
                      responsive
                      striped
                      hover
                      :items="items"
                    >
                      <template #cell(index)="row">
                        <div class="p-2">
                          {{ row.index + 1 }}
                        </div>
                      </template>
                      <template #cell(skill_name)="row">
                        <div class="p-2">
                          {{ getSkillName(row.item.skill_id) }}
                        </div>
                      </template>
                      <template #cell(result)="row">
                        <div class="p-2">
                          {{ getExamResult(row.item) }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="d-inline-block">
                    <router-link
                      v-if="$can('create question', 'Employee')"
                      :to="{ name: 'create-skill-question' }"
                      class="btn question-btn shadow-lg"
                      ><p class="m-0 btn-text">
                        Create Question
                        <span
                          ><img src="@/assets/images/icons/add-icon.png" alt=""
                        /></span></p
                    ></router-link>
                  </div>
                </div>

                <div>
                  <h3>Select Skill For Test</h3>
                  <div class="w-25 mb-5">
                    <b-form-group>
                      <v-select
                        v-model="selected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="skill"
                        :options="options"
                        :reduce="(options) => options"
                        class="select-size-sm"
                        @input="getQuestions(selected.id)"
                        placeholder="Select Skill"
                      />
                    </b-form-group>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-5">
              <b-col class="">
                <button
                  :disabled="!selected"
                  class="btn update-btn"
                  @click.prevent="next()"
                >
                  Start
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col v-show="skillStep === 2">
      <b-row
        class="rounded-lg p-2 shadow-lg d-flex justify-content-end"
      >
        <b-col cols="12" md="10">
          <b-row class="rounded-lg">
            <b-col class="text-left py-1">
              <div class="d-flex justify-content-between py-1">
                <h3>Skill Test</h3>
                <div>
                  <button
                    class="btn question-btn shadow-lg mr-1"
                    @click.prevent="backStep()"
                  >
                    <p class="m-0 btn-text">Back</p>
                  </button>
                  <router-link
                    v-if="$can('create question', 'Employee')"
                    :to="{ name: 'create-skill-question' }"
                    class="btn question-btn shadow-lg"
                    ><p class="m-0 btn-text">
                      Create Question
                      <span
                        ><img src="@/assets/images/icons/add-icon.png" alt=""
                      /></span></p
                  ></router-link>
                </div>
              </div>
              <p>
                Ornare neque enim vulputate mi augue. Lectus ipsum fringilla
                quam massa nec scelerisque. Ridiculus pulvinar nec, scelerisque
                ac mi.
              </p>
            </b-col>
          </b-row>
          <br />
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center vh-100 mb-3"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-form>
              <b-row v-for="(value, key) in questions" :key="key">
                <b-col v-if="value.question_type == 'text'">
                  <b-form-group
                    :label="key + 1 + '. ' + value.name + ' ?'"
                    label-for="1. Question 1"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      <b-form-radio-group
                        :id="'radio' + key"
                        v-model="value.selected"
                        :options="value.options"
                        :aria-describedby="ariaDescribedby"
                        :name="'radio-options' + key"
                        value-field="id"
                        text-field="name"
                        @input="answerChosen(value)"
                      ></b-form-radio-group>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col v-if="value.question_type == 'image'">
                  <img
                    :src="$store.state.appConfig.imageBaseUrl + value.image"
                    height="100"
                  />
                  <b-form-group
                    :label="key + 1 + '. ' + value.name + ' ?'"
                    label-for="1. Question 1"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      <b-form-radio-group
                        :id="'radio' + key"
                        v-model="value.selected"
                        :options="value.options"
                        :aria-describedby="ariaDescribedby"
                        :name="'radio-options' + key"
                        value-field="id"
                        text-field="name"
                        @input="answerChosen(value)"
                      ></b-form-radio-group>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>

            <b-row>
              <b-col class="">
                <button
                  type="submit"
                  class="btn update-btn"
                  @click.prevent="submit"
                >
                  Submit
                </button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { toast } from "@/utils/toast";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormRadio,
BFormRadioGroup,
BFormSelect,
BFormTextarea,
BModal,
BRow,
BSpinner,
BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BFormRadio,
    BModal,
    BFormRadioGroup,
    BSpinner,
    BTable,
    vSelect,
  },
  data() {
    return {
      ariaDescribedby: "",
      radio: "",
      skillStep: 1,
      hasSeenCongrats: false,
      serialNumber: "",
      selected: null,
      items: [],
      parentFields: [
        {
          key: "index",
          label: "Index",
        },
        {
          key: "skill_name",
          label: "Skill Name",
          sortable: true,
        },
        {
          key: "marks",
          label: "Optained Marks",
          sortable: true,
        },
        {
          key: "result",
          label: "Result",
          sortable: true,
        },
      ],
      form: {
        email: "",
        jobTitle: "",
        catagory: "",
        vacancy: "",
        location: "",
        food: null,
        checked: [],
      },

      show: true,
      questions: [],
      answer: {},
      options: [],
      given_ans: [],
      loading: false,
    };
  },
  computed: {
    validation() {
      return this.serialNumber.length >= 1;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.getSkills();
      this.getSkillTestResult();
    }
  },
  methods: {
    getSkillTestResult() {
      this.$store
        .dispatch("employee/getSkillTestResult", this.$route.params.id)
        .then((res) => {
          if (res.status == 200) {
            this.items = res.data.data;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSkillName(id) {
      if (this.options.length > 0) {
        let skill = this.options.find((x) => x.id == id);
        return skill.skill;
      }
    },
    getExamResult(item) {
      // if marks is greater than 60% then pass else fail
      let result = item.marks / item.questions_count;
      if (result > 0.6) {
        return "Pass";
      } else {
        return "Fail";
      }
    },
    prev() {
      this.skillStep--;
      //showravofficial@gmail.com,Lt.yakub@gmail.com,pritomshajed@gmail.com,injamulhaqsohag19977@gmail.com
    },

    next() {
      this.skillStep++;
      this.getQuestions();
    },
    submit() {
      this.loading = true;
      let marks = 0;
      this.given_ans.forEach((value) => {
        if (value.option_id == value.currect_option_id) {
          marks++;
        }
      });
      let data = {
        employee_id: this.$route.params.id,
        skill_id: this.selected.id,
        marks: marks,
        given_ans: this.given_ans,
      };
      if (data.given_ans.length > 0) {
        this.$store
          .dispatch("employee/saveSkillTest", data)
          .then((res) => {
            if (res.status === 200) {
              this.getSkillTestResult();
              this.loading = false;
              this.backStep();
              toast(
                "Success",
                "CheckCircleIcon",
                "success",
                "Skill Test Successfully"
              );
            }
          })
          .catch((e) => {
            this.loading = false;
            this.backStep();
            toast(
              "Error",
              "AlertTriangleIcon",
              "error",
              e.response?.data?.message ||
                "Something went wrong! Please try again"
            );
          });
      }
    },
    answerChosen(question) {
      let ans = {
        question_id: question.id,
        option_id: question.selected,
        currect_option_id: question.options.find(
          (option) => option.correct == 1
        ).id,
        answer: question.options.find(
          (option) => option.id == question.selected
        ).correct,
        correct: question.options.find((option) => option.correct == 1).correct,
      };

      if (this.given_ans.length > 0) {
        let index = this.given_ans.findIndex(
          (value) => value.question_id == question.id
        );
        // if index is not found then push the answer
        if (index == -1) {
          this.given_ans.push(ans);
        } else {
          // if index is found then update the answer
          this.given_ans.splice(index, 1, ans);
        }
      } else {
        this.given_ans.push(ans);
      }
    },
    customerRegister() {
      this.hasSeenCongrats = true;
    },
    getSkills() {
      this.$store
        .dispatch("employee/getSkills")
        .then((res) => {
          if (res.status_code === 200) {
            this.options = res.data;
          } else {
            this.errorMsg = "couldn't inserted";
          }
        })
        .catch((err) => {
          this.errorMsg = "please check code";
        });
    },
    getQuestions(skill_id) {
      if (!skill_id) return;
      this.$store
        .dispatch("question/getQuestions", { skill_id: skill_id })
        .then((res) => {
          this.questions = res.data.questions;
        })
        .catch((err) => {
          this.errorMsg = "please check code";
        });
    },
    backStep() {
      this.skillStep = 1;
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}
.question-btn {
  background: linear-gradient(315deg, white 0%, white 44.92%, white 100%);
  border-radius: 5px;
}
.btn-text {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.bank-text {
  color: #7190ef;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
}
.c-width {
  width: 85%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
