<template>
  <div>
    <div v-if="$route.params.id" class="mt-2 rounded-lg p-2 shadow-lg">
      <!-- <h3 class="title text-center pb-2">Update Employee Profile Image</h3> -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="12" lg="2">
          <div v-if="!image" class="file myDIV w-100">
            <label for="input-file-1">
              <img src="@/assets/images/icons/upload-image.png" alt="upload-img" class="img-fluid"
                style="height: 136px" />
            </label>
          </div>
          <div v-else class="img-border rounded-circle">
            <img :src="
              image
                ? image
                : employee.avatar &&
                  employee.avatar.includes('https://ui-avatars.com')
                  ? employee.avatar
                  : $store.state.appConfig.imageBaseUrl + employee.avatar
            " alt="upload-img" class="rounded-circle user-img" />
          </div>
        </b-col>

        <b-col cols="12" lg="10">
          <b-form>
            <b-form-group>
              <div class="w-100">
                <label for="input-file-1">
                  <img src="@/assets/images/icons/upload-your-photo.png" alt="upload-img" class="img-fluid" />
                </label>
                <input class="d-none" @change="handleFile($event)" id="input-file-1" type="file" />
              </div>
            </b-form-group>
            <b-row v-if="id > 0">
              <b-col class="text-right">
                <button v-if="$route.params.id > 0 && $can('update', 'Employee')" @click.prevent="handleUpdate"
                  class="btn update-btn">
                  Upload
                </button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <div v-else class="mt-2 rounded-lg bg-white p-2 shadow-lg text-center py-4">
      <p class="text-warning">please insert previous data </p>
    </div>
  </div>
</template>

<script>
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    BFormRadio,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      image: "",
      formData: {
        id: "",
        avatar: "",
      },
    };
  },
  methods: {
    prev() {
      this.skillStep--;
    },

    next() {
      this.skillStep++;
    },
    handleFile(e) {
      let file = e.target.files[0];
      // make base64 image for show preview
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result;
      };

      let formData = new FormData();
      formData.append("avatar", file);
      formData.append("id", this.$route.params.id);
      this.formData = formData;
    },
    handleUpdate() {
      this.$store
        .dispatch("employee/uploadPhoto", this.formData)
        .then((res) => {
          toast(
            "Successfully Updated",
            "CheckCircleIcon",
            "success",
            "Profile Updated Successfully"
          );
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.errors;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  color: #515b60;
}

.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.question-btn {
  background: linear-gradient(315deg, white 0%, white 44.92%, white 100%);
  border-radius: 5px;
}

.btn-text {
  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/*=================================================================*/

/* .file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }
  50% {
    color: hsl(48, 100%, 38%);
  }
  100% {
    color: hsl(48, 100%, 67%);
  }
} */
/* .hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
} */
.img-border {
  padding: 10px;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  height: 160px;
  width: 160px;
}

.user-img {
  background-color: white;
  padding: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
</style>
