<template>
  <b-row
    id="emp-per-info"
    class="d-flex flex-row justify-content-center shadow-lg mt-2 rounded-lg"
  >
    <validation-observer ref="simpleRules">
      <b-col>
        <b-form ref="form">
          <b-row class="rounded-lg p-2">
            <b-col cols="12" lg="2" class="text-center">
              <div class="mt-5">
                <div class="file myDIV">
                  <label for="input-file">
                    <div
                      v-if="!basic_info.avatar && !image"
                      class="file myDIV w-100"
                    >
                      <label for="input-file">
                        <img
                          src="@/assets/images/icons/upload-image.png"
                          alt="upload-img"
                          class="img-fluid"
                          style="height: 136px"
                        />
                      </label>
                    </div>

                    <div v-else class="img-border rounded-circle">
                      <img
                        :src="
                          image
                            ? image
                            : basic_info.avatar &&
                              basic_info.avatar.includes(
                                'https://ui-avatars.com'
                              )
                            ? basic_info.avatar
                            : $store.state.appConfig.imageBaseUrl +
                              basic_info.avatar
                        "
                        alt="upload-img"
                        class="rounded-circle user-img"
                      />
                    </div>
                  </label>
                  <input
                    @change="getImage($event)"
                    id="input-file"
                    type="file"
                  />
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="10">
              <b-row class="rounded-lg">
                <b-col class="text-left py-1">
                  <h3>Basic Information</h3>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  (logedInUser.roles[0].name === 'superadmin' ||
                    logedInUser.roles[0].name === 'admin') &&
                  basic_info.register === 'candidate'
                "
                class="rounded-lg"
              >
                <b-col class="text-left py-1">
                  <b-form-group
                    ><label for="candidate">Select</label>
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="register"
                        name="candidate"
                        class="mr-2 data-text"
                        value="candidate"
                        >Candidate</b-form-radio
                      >
                      <b-form-radio
                        v-model="register"
                        name="candidate"
                        class="data-text"
                        value="system"
                        >Employee</b-form-radio
                      >
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-if="
                    logedInUser.roles[0].name === 'superadmin' &&
                    register !== 'candidate'
                  "
                  cols="12"
                  md="6"
                >
                  <label for="" class="label-font">Subsidiary Company </label>
                  <b-form-group>
                    <v-select
                      v-model="selectedCompany"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="companies"
                      label="name"
                      placeholder="Select Subsidiary Company"
                      @input="getCustomers"
                      :disabled="
                        basic_info.employee_to_company &&
                        basic_info.employee_to_company.length > 0
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="
                    (logedInUser.roles[0].name === 'superadmin' ||
                      logedInUser.roles[0].name === 'admin') &&
                    register !== 'candidate'
                  "
                  cols="12"
                  md="6"
                >
                  <label for="" class="label-font">Client</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedCustomer"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customers"
                      label="name"
                      placeholder="Select Client"
                      multiple
                      persistent-hint
                    />
                    <small class="text-danger">
                      If you deselect any client, then the client will be
                      deassigned from the employee.
                    </small>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label for="">
                    First Name <span class="text-danger">*</span></label
                  >
                  <b-form-group label-for="fname">
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="basic_info.first_name"
                        placeholder="First Name"
                        required
                        class="data-text bg-light"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label for="Last Name"
                    >Last Name <span class="text-danger">*</span></label
                  >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="basic_info.last_name"
                        placeholder="Last Name"
                        required
                        class="data-text bg-light"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label for="">
                    Contact Number <span class="text-danger">*</span></label
                  >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        v-model="basic_info.phone"
                        placeholder="Enter Phone Number"
                        required
                        type="number"
                        class="data-text bg-light"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <label for="">
                    Email Address <span class="text-danger">*</span>
                  </label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Email Address"
                      rules="required"
                    >
                      <b-form-input
                        v-model="basic_info.email"
                        placeholder="Email Address"
                        required
                        class="data-text bg-light"
                        type="email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="logedInUser.roles[0].name !== 'customer'"
                  cols="12"
                  md="6"
                >
                  <label for="Date of Birth">
                    Date of Birth <span class="text-danger">*</span>
                  </label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Date of Birth"
                      rules="required"
                    >
                      <flat-pickr
                        placeholder="Date Of Birth"
                        required
                        class="date-data-text bg-light"
                        v-model="basic_info.birth_date"
                        :config="{
                          altInput: true,
                          altFormat: 'F j, Y',
                          dateFormat: 'Y-m-d',
                          required: true,
                        }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    ><label for="gender">Gender</label>
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="basic_info.gender"
                        name="gender"
                        id="male"
                        class="mr-2 data-text"
                        value="M"
                        >Male</b-form-radio
                      >
                      <b-form-radio
                        v-model="basic_info.gender"
                        name="gender"
                        id="female"
                        class="data-text"
                        value="F"
                        >Female</b-form-radio
                      >
                    </div>
                  </b-form-group>
                </b-col>
                <b-col id="visa" cols="12" md="6">
                  <label for="nationality">
                    Nationality <span class="text-danger">*</span>
                  </label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Nationality"
                      rules="required"
                    >
                      <v-select
                        id="nationality"
                        v-model="SelectedCountry"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="countries"
                        placeholder="Select Nationality"
                        class="data-text bg-light"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Citizen Type"
                    label-for="Citizen Type"
                    class="data-text"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-radio
                        v-model="resident_type"
                        name="some-radios"
                        class="mr-2 data-text"
                        value="1"
                        >AU/NZ Citizen</b-form-radio
                      >
                      <b-form-radio
                        v-model="resident_type"
                        name="some-radios"
                        class="data-text"
                        value="0"
                        >Non AU/NZ Citizen</b-form-radio
                      >
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="resident_type == 0 ? true : false"
                  cols="12"
                  class="py-1"
                >
                  <fieldset class="border px-2">
                    <legend class="float-none w-auto px-1">Visa Details</legend>
                    <b-row>
                      <b-col cols="12" md="6">
                        <label for="address">
                          Visa Type <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Visa Type"
                            rules="required"
                          >
                            <v-select
                              id="visa_type"
                              v-model="SelectedVisa"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              label="name"
                              :options="visaTypes"
                              placeholder="Select Visa"
                              class="data-text bg-light"
                              @input="getSelectedVisaInfo"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="postal_code">
                          Visa Sub Class <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Visa Sub Class"
                            rules="required"
                          >
                            <v-select
                              id="sub_class"
                              v-model="SelectedVisaSubClass"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              label="name"
                              :options="visaSubClass"
                              placeholder="Select Visa"
                              class="data-text bg-light"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col v-if="SelectedVisaSubClass && SelectedVisaSubClass.id === 7" cols="12" md="6">
                        <label for="Suburb">
                          Remarks <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Remarks"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.type"
                              placeholder="Enter Your Remarks"
                              required
                              class="data-text bg-light"
                              id="visa_remarks"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="Suburb">
                          Visa Grant Number <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Visa Grant Number"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.visa_grant_number"
                              placeholder="Enter Your Visa Grant Number"
                              required
                              class="data-text bg-light"
                              id="visa_grant_number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="State">
                          Visa Expiry Date <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Visa Expiry Date"
                            rules="required"
                          >
                            <flat-pickr
                              v-model="basic_info.expiry_date"
                              required
                              placeholder="Visa Expiry Date"
                              class="data-text bg-light flat-pickar"
                              :config="{
                                altInput: true,
                                altFormat: 'F j, Y',
                                dateFormat: 'Y-m-d',
                              }"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>

                  <fieldset
                    v-if="is_postal_same_as_residential === 0"
                    class="border px-2"
                  >
                    <legend class="float-none w-auto px-1">
                      Postal Address
                    </legend>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group>
                          <b-form-textarea
                            id="textarea"
                            v-model="basic_info.postal_address"
                            placeholder="Enter Residential Address"
                            rows="3"
                            max-rows="6"
                            class="data-text bg-light"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col cols="12" class="py-1">
                  <fieldset class="border px-2">
                    <legend class="float-none w-auto px-1">
                      Residential Address
                    </legend>
                    <b-row>
                      <b-col cols="12" md="6">
                        <label for="address">
                          Address <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Address"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.address"
                              placeholder="I.G House, Road, Area"
                              required
                              class="data-text bg-light"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="postal_code">
                          Postal Code <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Zip Code"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.zip_code"
                              placeholder="Zip Code"
                              class="data-text bg-light"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="Suburb">
                          Suburb <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Suburb"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.city"
                              placeholder="Enter Your Living Suburb"
                              required
                              class="data-text bg-light"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <label for="State">
                          State <span class="text-danger">*</span>
                        </label>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="State"
                            rules="required"
                          >
                            <b-form-input
                              v-model="basic_info.region"
                              placeholder="Enter Your Living State"
                              required
                              class="data-text bg-light"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <b-row>
                    <b-col cols="12 mt-1">
                      <b-form-group>
                        <b-form-checkbox
                          id="checkbox-1"
                          v-model="is_postal_same_as_residential"
                          name="checkbox-1"
                          :value="1"
                          :unchecked-value="0"
                        >
                          <span class="data-text"
                            >Postal address is same as residential address</span
                          >
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <fieldset
                    v-if="is_postal_same_as_residential === 0"
                    class="border px-2"
                  >
                    <legend class="float-none w-auto px-1">
                      Postal Address
                    </legend>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group>
                          <b-form-textarea
                            id="textarea"
                            v-model="basic_info.postal_address"
                            placeholder="Enter Residential Address"
                            rows="3"
                            max-rows="6"
                            class="data-text bg-light"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col
                  v-if="
                    logedInUser.roles[0].name === 'superadmin' ||
                    logedInUser.roles[0].name === 'admin'
                  "
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Payment Per Hour"
                    label-for="Payment Per Hour"
                  >
                    <b-form-input
                      v-model="basic_info.payment_per_hour"
                      placeholder="Enter Your Working Sector"
                      required
                      class="data-text bg-light"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Employment Type"
                    label-for="EmploymentType"
                    class="data-text"
                  >
                    <v-select
                      id="EmploymentType"
                      v-model="SelectedEmploymentType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="employmentTypes"
                      placeholder="Select Employment Type"
                      class="data-text bg-light"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Job Role"
                    label-for="Job Roles"
                    class="data-text"
                  >
                    <v-select
                      id="Sector"
                      v-model="SelectedSector"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="duties"
                      placeholder="Select Job Roles"
                      class="data-text bg-light"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="basic_info.register !== 'candidate'" cols="12" md="6">
                  <b-form-group label="Position" label-for="Position">
                    <v-select
                      id="Position"
                      v-model="SelectedPosition"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="positions"
                      placeholder="Select Position"
                      class="data-text bg-light"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="basic_info.register === 'candidate' && logedInUser.roles[0].name !== 'employee'" cols="12" md="6">
                  <b-form-group label="Note" label-for="note">
                    <b-form-textarea
                      id="note"
                      v-model="basic_info.note"
                      placeholder="Enter Note"
                      rows="3"
                      max-rows="6"
                      class="data-text bg-light"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr />
              <h4>Availability</h4>
              <b-row class="d-flex" v-for="(day, index) in days" :key="index">
                <b-col cols="12" md="3" class="pr-0">
                  <label for=""> Day <span class="text-danger">*</span> </label>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Day"
                      rules="required"
                    >
                      <v-select
                        id="Day"
                        v-model="day.day"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="weekDays"
                        placeholder="Select Day"
                        class="data-text bg-light"
                        @input="getSelectedDay(day, index)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <label for=""> Start Time </label>
                  <b-form-group
                    :state="day.startState"
                    invalid-feedback="Start time is required"
                  >
                    <b-form-timepicker
                      class="bg-light"
                      v-model="day.start_time"
                      locale="en"
                      @input="checkFormValidity(day, index)"
                      now-button
                      label-close-button="Set"
                      :reset-button="day.endState === false"
                      :no-close-button="day.endState === false"
                    ></b-form-timepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <label for=""> End Time {{ day.endState }}</label>
                  <b-form-group
                    invalid-feedback="End time is required"
                    :state="day.endState"
                  >
                    <b-form-timepicker
                      class="bg-light"
                      v-model="day.end_time"
                      locale="en"
                      @input="checkFormValidity(day, index)"
                      now-button
                      label-close-button="Set"
                      :reset-button="day.endState === false"
                      :no-close-button="day.endState === false"
                    ></b-form-timepicker>
                  </b-form-group>
                </b-col>
                <b-col class="p-0 mt-3" cols="12" md="1">
                  <button
                    v-if="days.length > 1"
                    @click.prevent="remove(day, index)"
                    class="btn btn-sm btn-danger custom"
                  >
                    x
                  </button>
                  <button
                    v-if="days.length - 1 === index && days.length < 7"
                    @click.prevent="addNew"
                    class="btn btn-sm btn-success custom"
                  >
                    +
                  </button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="text-right my-3">
                  <button
                    v-if="basic_info.id && $can('update', 'Employee')"
                    class="btn update-btn"
                    @click.prevent="handleSubmit"
                  >
                    Save
                  </button>
                  <div v-else>
                    <button
                      v-if="$can('create', 'Employee')"
                      class="btn update-btn"
                      @click.prevent="handleSubmit"
                    >
                      Create
                    </button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </validation-observer>
  </b-row>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormSelect,
  BFormTextarea,
  BFormTimepicker,
  BRow,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@validations";
import moment from "moment";
export default {
  components: {
    BButton,
    BCard,
    BCol,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    BFormTimepicker,
    flatPickr,
    vSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    basic_info: {
      type: Object,
      default: () => {},
    },
    availability: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      logedInUser: JSON.parse(getLogedInUser()),
      query: {
        keyword: "",
        // page: 1,
        // per_page: 500,
      },
      is_postal_same_as_residential: 1,
      resident_type: "0",
      nameState: null,
      lNameState: null,
      phone: null,
      email: null,
      dayState: null,
      startState: null,
      endState: null,
      dboState: null,
      nationalityState: null,
      addressState: null,
      postalCodeState: null,
      cityState: null,
      regionState: null,
      duties: [],
      role: ["admin", "executive", "hr", "operator"],
      positions: [],
      countries: [],
      employmentTypes: [],
      SelectedCountry: null,
      SelectedEmploymentType: null,
      SelectedSector: null,
      SelectedPosition: null,
      image: "",
      visaTypes: [],
      visaSubClass: [],
      SelectedVisaSubClass: null,
      SelectedVisa: null,
      days: [
        {
          day: null,
          day_id: null,
          start_time: "",
          end_time: "",
          dayState: null,
          startState: null,
          endState: null,
        },
      ],
      weekDays: [],
      email,
      required,
      companies: [],
      customers: [],
      selectedCompany: null,
      selectedCustomer: null,
      register: "",
    };
  },
  created() {
    this.getCountries();
    this.getEmploymentTypes();
    this.getDuties();
    this.getPositions();
    this.getVisaTypes();
    if (this.logedInUser.roles[0].name === "superadmin") {
      if (
        this.basic_info.employee_to_company &&
        this.basic_info.employee_to_company.length > 0
      ) {
        this.selectedCompany = this.basic_info.employee_to_company[0];
        this.getCustomers();
      } else {
        this.getCompanies();
      }
    }

    if (this.logedInUser.roles[0].name === "admin") {
      let roles = this.logedInUser.roles.map((role) => {
        return role.name;
      });
      if (roles.includes("supervisor")) {
        this.selectedCompany = this.logedInUser.supervisor.company;
      } else {
        this.selectedCompany = this.logedInUser;
      }
      this.getCustomers();
    }
  },
  mounted() {
    this.getDay();
    if (this.availability.length > 0) {
      this.days = this.availability;
    }
    this.is_postal_same_as_residential =
      this.basic_info.is_postal_same_as_residential;
    if (this.basic_info.resident_type) {
      this.resident_type = this.basic_info.resident_type.toString();
    } else {
      this.resident_type = "0";
    }

    let query = this.$route.query;
    if (query && query.type === "visa") {
      this.$nextTick(() => {
        let el = document.getElementById("visa");
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      });
    }
    if (this.basic_info.register === "candidate") {
      this.register = "candidate";
    }
  },
  methods: {
    getCompanies() {
      let query = {
        per_page: 1000,
      };
      this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then(({ data }) => {
          this.companies = data;
        });
    },
    async getCustomers() {
      let query = {};
      if (this.selectedCompany && this.selectedCompany.id) {
        query.user_id = this.selectedCompany.id;
      } else {
        query.user_id = "";
      }
      if (query.user_id) {
        await this.$store
          .dispatch("customer/getCustomersForDropdown", query)
          .then((response) => {
            // reassigned the customers to the new array which has the customer_to_company.length > 0 in customer object
            this.customers = response.data;
            this.selectedCustomer =
              this.basic_info.employee_customer &&
              this.basic_info.employee_customer.length > 0
                ? this.basic_info.employee_customer
                : [];
          });
      }
    },
    getVisaTypes() {
      this.$store.dispatch("employee/getVisa").then((res) => {
        this.visaTypes = res.data;

        if (this.mode === "edit") {
          this.SelectedVisa = this.visaTypes.find(
            (visa) => visa.id == this.basic_info.visa_type
          );
          this.getSelectedVisaInfo();
        }
      });
    },
    getSelectedVisaInfo() {
      if (this.SelectedVisa && this.SelectedVisa.id) {
        this.$store
          .dispatch("employee/getVisaSubClass", this.SelectedVisa.id)
          .then((res) => {
            this.visaSubClass = res.data;

            if (this.mode === "edit") {
              this.SelectedVisaSubClass = this.visaSubClass.find(
                (visa) => visa.id == this.basic_info.sub_class
              );
            }
          });
      }
    },
    getDay() {
      this.$store.dispatch("employee/getDay").then((res) => {
        if (res.data.status_code == 200) {
          this.weekDays = res.data.data;
        }
      });
    },
    addNew() {
      this.days.push({
        day: null,
        day_id: null,
        start_time: this.days[this.days.length - 1].start_time,
        end_time: this.days[this.days.length - 1].end_time,
      });
    },
    remove(day, index) {
      if (this.days.length > 1) {
        if (day.id) {
          let query = {
            id: day.id,
            employee_id: this.basic_info.id,
          };
          this.daleteDay(query);
        }
        this.days.splice(index, 1);
      }
    },
    daleteDay(query) {
      this.$store.dispatch("employee/deleteDay", query).then((res) => {
        if (res.data.status_code == 200) {
          toast("Success", "CheckIcon", "success", "Day deleted successfully!");
        } else {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Something went wrong!"
          );
        }
      });
    },
    getSelectedDay(day, index) {
      // if day is selected then show error
      let existDay = this.days.find((d) => d.day_id == day.day?.id);
      if (existDay) {
        this.days[index].day_id = "";
        this.days[index].day = null;
        toast("Error", "AlertTriangleIcon", "danger", "Day already selected!");
      } else {
        this.days[index].day_id = day.day?.id;
      }
    },
    handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.SelectedCountry) {
            this.basic_info.nationality = this.SelectedCountry.id;
          }

          this.basic_info.is_postal_same_as_residential =
            this.is_postal_same_as_residential;
          if (this.is_postal_same_as_residential === 1) {
            this.basic_info.postal_address = "";
          }
          if (this.SelectedVisa) {
            this.basic_info.visa_type = this.SelectedVisa.id;
          }
          if (this.SelectedVisaSubClass) {
            this.basic_info.sub_class = this.SelectedVisaSubClass.id;
          }
          if (this.selectedCompany && this.selectedCompany.id) {
            this.basic_info.company_id = this.selectedCompany.id;
          }
          if (this.selectedCustomer && this.selectedCustomer.length > 0) {
            this.basic_info.customer_id = this.selectedCustomer.map(
              (customer) => customer.id
            );
          } else {
            this.basic_info.customer_id = [];
          }

          this.basic_info.resident_type = this.resident_type;

          if (this.basic_info.id) {
            if (this.basic_info.register === "candidate") {
              this.updateCandidate();
            } else {
              this.update();
            }
          } else {
            this.create();
          }
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
    checkFormValidity(day, index) {
      let valid = this.$refs.form.checkValidity();
      this.days[index].startState = null;
      this.days[index].endState = null;
      // if day.start_time && day.end_time defference is greater than 12 hours and less than 0 hours using moment js
      if (day.start_time && day.end_time) {
        let start = moment(day.start_time, "HH:mm");
        let end = moment(day.end_time, "HH:mm");
        let diff = end.diff(start, "hours");
        if (diff > 12 || diff < 0) {
          this.days[index].end_time = "";
          this.days[index].start_time = "";
          // close bootstrap vue timepicker
          this.days[index].dayState = false;
          this.days[index].startState = false;
          this.days[index].endState = false;
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "End time should be greater than start time and less than 12 hours!"
          );
        }
      }
      return valid;
    },
    getImage(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.image = e.target.result;
      };
    },
    create() {
      if (this.SelectedEmploymentType) {
        this.basic_info.employment_type = this.SelectedEmploymentType.id;
      }
      if (this.SelectedSector) {
        this.basic_info.sector = this.SelectedSector.id;
      }
      if (this.SelectedPosition) {
        this.basic_info.position = this.SelectedPosition.id;
      }
      this.basic_info.avatar = this.image;
      if (this.logedInUser.roles[0].name === "admin") {
        this.basic_info.company_id = this.logedInUser.id;
      }
      if (this.days.length > 0) {
        this.basic_info.days = this.days;
      }
      this.$store
        .dispatch("employee/createEmployee", this.basic_info)
        .then((res) => {
          if (res.status_code === 200) {
            this.$router.push({
              path: `/employee-profile-update/${res.data.id}`,
            });
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Employee Create Successfully"
            );
          } else {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Error Creating Employee"
            );
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.error;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
    updateCandidate() {
      if (this.SelectedEmploymentType) {
        this.basic_info.employment_type = this.SelectedEmploymentType.id;
      }
      if (this.SelectedSector) {
        this.basic_info.sector = this.SelectedSector.id;
      }
      if (this.SelectedPosition) {
        this.basic_info.position = this.SelectedPosition.id;
      }
      this.basic_info.avatar = this.image ? this.image : this.basic_info.avatar;
      if (this.days.length > 0) {
        this.basic_info.days = this.days;
      }
      this.$store
        .dispatch("employee/updateCandidate", this.basic_info)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Profile Updated Successfully"
            );
          } else {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Error Updating Employee"
            );
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.error;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
    update() {
      if (this.SelectedEmploymentType) {
        this.basic_info.employment_type = this.SelectedEmploymentType.id;
      }
      if (this.SelectedSector) {
        this.basic_info.sector = this.SelectedSector.id;
      }
      if (this.SelectedPosition) {
        this.basic_info.position = this.SelectedPosition.id;
      }
      this.basic_info.avatar = this.image ? this.image : this.basic_info.avatar;
      if (this.days.length > 0) {
        this.basic_info.days = this.days;
      }
      this.$store
        .dispatch("employee/updateEmployeeBasicInformation", this.basic_info)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Profile Updated Successfully"
            );
          } else {
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Error Updating Employee"
            );
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.error;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
    getCountries() {
      this.query = {
        page: 1,
        limit: 300,
      };
      this.$store
        .dispatch("country/getCountries", this.query)
        .then((response) => {
          this.countries = response.data.data;

          this.SelectedCountry = this.countries.find(
            (c) => c.id == this.basic_info.nationality
          );
        });
    },
    getEmploymentTypes() {
      this.$store
        .dispatch("employmentType/getEmploymentTypes", this.query)
        .then((response) => {
          this.employmentTypes = response.data.data;

          this.SelectedEmploymentType = this.employmentTypes.find(
            (c) => c.id == this.basic_info.employment_type
          );
        });
    },
    async getDuties() {
      let res = await this.$store.dispatch("duty/getDuties");
      this.duties = res.data.data;

      if (this.duties.length > 0) {
        this.SelectedSector = this.duties.find(
          (c) => c.id == this.basic_info.sector
        );
      }
    },

    getPositions() {
      this.$store
        .dispatch("position/getPositions", this.query)
        .then((response) => {
          this.positions = response.data.data;
          this.SelectedPosition = this.positions.find(
            (c) => c.id == this.basic_info.position
          );
        });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}

.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.img-border {
  padding: 10px;
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
}

.user-img {
  object-fit: cover;
  object-position: center;
}

.custom {
  padding: 5px 10px;
}
.btn-danger {
  margin-right: 5px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
