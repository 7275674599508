<template>
  <div class="px-1">
    <b-row>
      <b-col class="bg-white shadow-lg rounded-lg">
        <b-row v-if="!hasSeenCongrats" class="p-2 register-stepper">
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 1, 'step-done': step > 1 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-basic-information.png"
                alt=""
              />
              <p class="">Basic Information</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 2, 'step-done': step > 2 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-next-to-keen.png"
                alt=""
              />
              <p class="">Next To Kin</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 3, 'step-done': step > 3 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-necessary-docs.png"
                alt=""
              />
              <p class="">Necessary Docs.</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 4, 'step-done': step > 4 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-bank-information.png"
                alt=""
              />
              <p class="">Bank Information</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 5, 'step-done': step > 5 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img src="@/assets/images/icons/profile-skill-test.png" alt="" />
              <p class="">Skill Test</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 6, 'step-done': step > 6 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img src="@/assets/images/icons/profile-take-photo.png" alt="" />
              <p class="">Take Photo</p>
            </div>
          </b-col>
          <b-col
            class="step text-center"
            :class="{ 'step-active': step === 7, 'step-done': step > 7 }"
          >
            <div
              class="d-flex flex-column justify-content-center align-items-center py-2"
            >
              <img
                src="@/assets/images/icons/profile-change-password.png"
                alt=""
              />
              <p class="">Change Password</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-show="step === 1">
        <basic-information :basic_info="basic_info" @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 2">
        <next-to-keen :keen="employee.employee.keen" @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 3">
        <necessary-docs
          :passport="employee.employee.passport"
          :driving="employee.employee.driving"
          :certificate="employee.employee.certificate"
          :visaInfo="employee.employee.visa"
          @next="next"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 4">
        <bank-information @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 5">
        <skill-test @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 6">
        <take-photo @next="next" />
      </b-col>
    </b-row>
    <b-row>
      <b-col v-show="step === 7">
        <change-password @next="next" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCard, BCol, BRow } from "bootstrap-vue";
import BankInformation from "./components/bank-information.vue";
import BasicInformation from "./components/basic-information.vue";
import ChangePassword from "./components/change-password.vue";
import NecessaryDocs from "./components/necessary-docs.vue";
import NextToKeen from "./components/next-to-keen.vue";
import SkillTest from "./components/skill-test.vue";
import TakePhoto from "./components/take-photo.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BasicInformation,
    NextToKeen,
    NecessaryDocs,
    BankInformation,
    SkillTest,
    TakePhoto,
    ChangePassword,
  },
  data() {
    return {
      step: 1,
      hasSeenCongrats: false,
      basic_info: {
        avatar: "",
        first_name: "",
        last_name: "",
        birth_date: "",
        nationality: "",
        address: "",
        zip_code: "",
        position: "",
        city: "",
        region: "",
        payment_per_hour: "",
        EmploymentType: "",
        sector: "",
        phone: "",
        email: "",
        is_postal_same_as_residential: 1,
        resident_type: 0,
        employee_to_company: [],
        employee_customer: [],
      },
      employee: {
        employee: {
          passport: {
            passport_issue_country: "",
            passport_file: "",
            passport_number: "",
            passport_validation: "",
          },
          visa: {
            resident_type: null,
            expiry_date: "",
            visa_grant_number: "",
            visa_type: "",
            sub_class: "",
            employee_id: ""
          },
          driving: {
            issuing_state: "",
            licence_file: "",
            licence_number: "",
            license_validation: "",
          },
          keen: {
            address: "",
            relation: "",
            phone: "",
            name: "",
            email: "",
          },
          certificate: [
            {
              certificate_file: "",
              certificate_name: "",
              certificate_validation: "",
              certificate_issuing_authority: "",
              certificate_issuing_date: "",
              certificate_number: "",
            },
          ],
          bank_info: {
            account_type: "",
            address: "",
            bank_name: "",
            personal_account_number: "",
          },
          super: {
            address: "",
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
          },
        },
      },
    };
  },
  created() {},
  methods: {
    prev() {
      if (this.step > 0) {
        this.step--;
      }
    },

    next() {
      if (this.step < 7) {
        this.step++;
      }
    },
    switchStep(step) {
      this.step = step;
    },
  },
};
</script>

<style scoped>
.step-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 53px;

  /* Text Additional */

  color: #79869f;
}

.step-percentage {
  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* ===========================================================
    step area css
   =========================================================== */
/* VARIABLES */
/* COLORS */
/* FONT */

.register-stepper .step {
  /* 20 medium */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;

  /* Text 2 */
  color: #a8adaf;
  cursor: pointer;
}

.register-stepper .step-active {
  /* background-color: #fff;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #01c839, #016667) border-box;
  border: 2px solid transparent; */
  color: #7190ef;
  /* 20 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
}

.step img {
  margin-bottom: 20px;
  height: 70px;
}
</style>
