<template>
  <b-row
    class="d-flex flex-row justify-content-center mt-2 rounded-lg"
  >
    <b-col v-if="$route.params.id">
      <b-form ref="form">
        <b-row class="rounded-lg p-2 shadow-lg d-flex justify-content-end">
          <b-col class="text-center py-4">
            <img
              src="@/assets/images/icons/profile-bank-information.png"
              alt=""
            />
            <p class="bank-text text-center py-1">Bank Information</p>
          </b-col>
          <b-col cols="12" md="10">
            <b-row class="rounded-lg">
              <b-col class="text-left py-1">
                <h3>Bank Account Information</h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  invalid-feedback="Bank Name Is Required"
                  :state="bankName"
                >
                  <label for=""
                    >Bank Name <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="bank_info.bank_name"
                    placeholder="Enter Bank Name"
                    required
                    class="data-text bg-light"
                    :state="bankName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Account Name" label-for="Account-Name">
                  <b-form-input
                    id="Account-Name"
                    v-model="bank_info.address"
                    placeholder="Type Account Name"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group invalid-feedback="BSB Numbe Is Required" :state="bsb">
                  <label for=""
                    >BSB Number <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="bank_info.personal_bsb_number"
                    required
                    placeholder="Type BSB Number"
                    class="data-text bg-light"
                    :state="bsb"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group invalid-feedback="Account Number Is Rquired" :state="acc_number">
                  <label for=""
                    >Account number <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    @keyup="checkFormValidity"
                    v-model="bank_info.personal_account_number"
                    :state="acc_number"
                    required
                    placeholder="Type Account Number"
                    class="data-text bg-light"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>


            <hr />
            <h3>Super</h3>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group invalid-feedback="Fund Name Is Required" :state="fundName">
                  <label for=""
                    >Fund Name <span class="text-danger">*</span></label
                  >
                  <b-form-input
                    v-model="bank_info.super_name"
                    @keyup="checkFormValidity"
                    placeholder="Enter Fund Name"
                    required
                    class="data-text bg-light"
                    :state="fundName"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group invalid-feedback="USI Numbe Is Required" :state="unique_id">
                  <label for="">USI <span class="text-danger">*</span></label>
                  <b-form-input
                    v-model="bank_info.super_phone_number"
                    placeholder="Enter USI"
                    required
                    class="data-text bg-light"
                    :state="unique_id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Account Name" label-for="Account name">
                  <b-form-input
                    v-model="bank_info.super_account_number"
                    placeholder="Enter Account Name"
                    required
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Member Number" label-for="account_type">
                  <b-form-input
                    v-model="bank_info.account_type"
                    placeholder="Enter Member Number"
                    required
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="BSB Number" label-for="Super Address">
                  <b-form-input
                    v-model="bank_info.super_bsb_number"
                    required
                    placeholder="Type BSB Number"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="ABN" label-for="ABN">
                  <b-form-input
                    v-model="bank_info.super_abn"
                    required
                    placeholder="Type ABN Number"
                    class="data-text bg-light"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="id > 0 && $can('update', 'Employee')">
              <b-col class="text-right">
                <button class="btn update-btn" @click.prevent="handleSubmit">
                  Update
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <b-col v-else class="card text-center py-4">
      <p class="text-warning">please insert previous data</p>
    </b-col>
  </b-row>
</template>

<script>
import { toast } from "@/utils/toast";
import {
BButton,
BCard,
BCol,
BForm,
BFormCheckbox,
BFormCheckboxGroup,
BFormDatepicker,
BFormFile,
BFormGroup,
BFormInput,
BFormSelect,
BFormTextarea,
BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  props: {
    bank_info: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckboxGroup,
    BFormDatepicker,
    BFormCheckbox,
    BButton,
    BCard,
    BFormTextarea,
    BFormFile,
    vSelect,
  },
  data() {
    return {
      bankName: null,
      bsb: null,
      acc_number: null,
      fundName: null,
      unique_id: null,
      form: ["firstName", "lastName"],
      selected: "null",
      options: ["a", "b", "c"],
    };
  },
  methods: {
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      } else {
        this.update();
      }
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      if (this.bank_info.bank_name !== "") {
        valid = true;
        this.bankName = valid;
      } else {
        valid = false;
        this.bankName = valid;
      }
      if (this.bank_info.personal_bsb_number !== "") {
        valid = true;
        this.bsb = valid;
      } else {
        valid = false;
        this.bsb = valid;
      }
      if (this.bank_info.personal_account_number !== "") {
        valid = true;
        this.acc_number = valid;
      } else {
        valid = false;
        this.acc_number = valid;
      }
      if (this.bank_info.super_name !== "") {
        valid = true;
        this.fundName = valid;
      } else {
        valid = false;
        this.fundName = valid;
      }
      if (this.bank_info.super_name !== "") {
        valid = true;
        this.fundName = valid;
      } else {
        valid = false;
        this.fundName = valid;
      }
      return valid;
    },
    update() {
      this.bank_info.id = this.id
      this.$store
        .dispatch("employee/updateBankInfo", this.bank_info)
        .then((res) => {
          if (res.status === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Update Successfully"
            );
          } else {
            toast("Error", "AlertTriangleIcon", "danger", "Error Updating");
          }
        })
        .catch((error) => {
          let errorResponse = error.response?.data?.errors;
          for (const key in errorResponse) {
            if (errorResponse.hasOwnProperty(key)) {
              const errorMessages = errorResponse[key];
              // Loop through each error message in the array
              for (const errorMessage of errorMessages) {
                toast("Error", "AlertTriangleIcon", "danger", errorMessage);
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.update-btn {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
  color: white;
}
.data-text {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e !important;
}
.bank-text {
  color: #7190ef;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
}

/*=================================================================*/

.file {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.file > input[type="file"] {
  display: none;
}

.file > label {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    color: hsl(48, 100%, 67%);
  }

  50% {
    color: hsl(48, 100%, 38%);
  }

  100% {
    color: hsl(48, 100%, 67%);
  }
}

.hide {
  display: none;
  position: absolute;
  margin-top: -100px;
}

.myDIV:hover + .hide {
  display: block;
  color: red;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
<!-- 
<style lang="scss">
.data-text  {
  /* 18 bold */
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  /* blue main */
  color: #01185e;
}
</style> -->

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
